@import url(https://db.onlinewebfonts.com/c/92649d5606272c17cb8021b160c2460e?family=Edwardian+Script+ITC+Regular);

* {
    padding: 0%;
    margin: 0%;
}

body {
    padding: 0%;
    margin: 0%;
}

.main_certificate_section_div {
    width: 100%;
    height: 100vh;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 10px;
    position: fixed;
    top: 0%;
    z-index: 999;
    overflow: auto;
}

.inner_certificate_section_div1 {
    max-width: 1200px;
    width: 92%;
    height: fit-content;
    border: 10px solid #C81E25;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.inner_certificate_section_div1_web_logo_div {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.inner_certificate_section_div1_web_logo_div img {
    width: 420px;
    height: 120px;
}

.skillsevaluation_url_div {
    font-size: 18px;
    font-weight: 600;
}

.main_registration_and_date_number_div {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 7%;
}

.inner_certificate_section_div1_certificate_description_div {
    width: 100%;
    height: fit-content;
    padding: 0 7%;
    font-size: 24px;
    text-align: center;
}

.inner_certificate_section_div1_certificate_heading_div {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 60px;
    font-weight: 500 !important;
    font-family: Edwardian Script ITC;
    padding-bottom: 1%;
}

.inner_certificate_section_div1_certificate_seal_and_signature_div {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    padding-top: 3%;
}

.main_signature_div {
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 40px;
    padding-right: 13%;
}

.inner_signature_div1 {
    width: 200px;
    border-bottom: 1px solid #0A3161;
    text-align: center;
}

.inner_main_signature_div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.signature_heading {
    font-size: 18px;
    font-weight: 600;
}

.inner_signature_div2 {
    width: 200px;
    border-bottom: 1px solid #0A3161;
    text-align: center;
}

.inner_signature_div1 img {
    width: 200px;
    height: 70px;
    padding-bottom: 5px;
}

.inner_signature_div2 img {
    width: 200px;
    height: 70px;
    padding-bottom: 5px;
}

.main_seal_div {
    width: 120px;
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url(../../assets/images/AMERICAN_SKILLS_EVALUATION_INSTITUTE_seal.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 100px;
    margin-right: 7%;
}

.inner_certificate_section_div1_certificate_footer_div {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-bottom: 1%;
}

.blue_text {
    color: #0A3161;
    font-weight: 600;
}

.lightblue_text {
    color: #0563C1;
    font-weight: 600;
}

.sm_text {
    font-size: 15px;
}

.email_address_div {
    text-decoration: underline;
}

.super_main_show_star_div {
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 15px;
    position: absolute;
    left: 5%;
    bottom: 5%;
    color: gray;
}

.super_main_show_star_div .text {
    font-size: 20px;
    font-weight: bolder;
    border-bottom: 2px solid gray;
}

.super_main_show_star_div .text div {
    padding: 3px 0;
}

.main_show_star_div {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    gap: 10px;
    margin-top: -10px;
    border-radius: 5px;
    padding-top: 2px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 10px;
}

.inner_show_star_div1 {
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
}

.inner_show_star_div1 div {
    width: fit-content;
    height: fit-content;
    font-size: 15px;
}

.inner_show_star_div2 {
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 8px;
}

.inner_show_star_div2 div {
    display: flex;

}