* {
    margin: 0%;
    padding: 0%;
}

body {
    margin: 0%;
    padding: 0%;
}

.subheading {
    font-size: 30px !important;
    font-weight: bolder !important;
}

.main_accreditation_hero_section_div {
    width: 100%;
    height: 400px;
    background-image: url("https://storage.googleapis.com/asei-632d2.appspot.com/web_assets/1712680871976-blog_banner_img.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.main_popup_box_div {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.497);
}

.inner_popup_box_div {
    width: 40%;
    height: 40%;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 5px gray;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 1rem;
}

.inner_popup_box_div h1 {
    font-size: 30px;
    font-weight: bolder;
}

.inner_popup_box_div p {
    text-align: center;
}

.inner_popup_box_div button {
    padding: 7px 27px;
    border-radius: 10px;
    background-color: #03a07b;
    color: white;
    font-size: 20px;
    font-weight: bold;
}



@media screen and (max-width: 850px) {
    .inner_popup_box_div {
        width: 90% !important;
        height: fit-content !important;
        gap: 1rem !important;
    }

    .inner_popup_box_div h1 {
        text-align: center !important;
    }

    .left_blog_hero_section_div {
        font-size: 35px !important;
    }
}

@media screen and (max-width: 650px) {}