* {
    margin: 0%;
    padding: 0%;
}

body {
    margin: 0%;
    padding: 0%;
}

.main_privacy_policy_div {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 125px;
}

.inner_privacy_policy_div {
    width: 90%;
    height: fit-content;
    margin: 3% 0;
    padding: 20px;
    border-radius: 20px;
    border: 2px solid #195389;
    box-shadow: 0px 0px 10px 0px #195389;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 20px;
    background-image: url(../../assets/images/water_mark_background_image.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.inner_privacy_policy_div_heading {
    font-size: 25px;
    font-weight: bolder;
    color: #195389;
}

.inner_privacy_policy_div_sub_heading {
    font-size: 16px;
}

.inner_privacy_policy_div_sub_heading span {
    font-size: 35px;
    font-weight: bolder;
    color: #195389;
}