* {
    margin: 0%;
    padding: 0%;
}

body {
    margin: 0%;
    padding: 0%;
}


.modal {
    width: 100%;
    display: none;
    position: fixed;
    z-index: 999;
    top: 0%;
}

.modal-content {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.notification_box {
    margin-top: 1%;
    width: 500px;
    /* height: 70px; */
    padding: 30px 0px;
    text-align: center;
    color: white;
    border-radius: 10px;
    background-color: rgb(57, 184, 63);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1%;
}

.modal.show {
    display: block;
    /* Additional styling for the visible modal */
    /* ... */
}