* {
    margin: 0%;
    padding: 0%;
}

body {
    margin: 0%;
    padding: 0%;
}

.main_Blog_review_1_div {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 125px;
}

.inner_Blog_review_1_div {
    width: 1200px;
    height: fit-content;
}

.main_show_blog_review_page {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 3% 0;
}

.inner_show_blog_review_page1 {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 30px;
    text-align: justify;
}

.hold_left_and_right_blog_review_div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.blog_review_content_div {
    width: 60%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.blog_review_image_div {
    width: 40%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.review_blog_page_heading {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.review_blog_page_heading h1 {
    font-size: 30px;
    font-weight: bolder;
}

.review_blog_page_heading h2 {
    font-size: 25px;
    font-weight: bolder;
}

.review_blog_page_heading img {
    width: 100%;
    border-radius: 10px;
}

.blog_image {
    width: 70% !important;
    height: 500px;
}





@media screen and (max-width: 850px) {
    .inner_Blog_review_1_div {
        width: 100%;
        padding: 2%;
    }

    .hold_left_and_right_blog_review_div {
        flex-direction: column;
    }

    .blog_review_content_div {
        width: 100%;
    }

    .blog_review_image_div {
        width: 100%;
    }
}