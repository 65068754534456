.main_footer_section {
    width: 100%;
    height: 100%;
    color: white;
    background-color: #195389;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 3% 1%;
    position: relative;
    top: 125px;
}

.main_footer_div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    /* align-items: center; */
    gap: 20px;
    /* background-color: crimson; */
}

.main_footer_inner_div1 {
    width: 33%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: center;
    flex-wrap: wrap;
    /* background-color: red; */
}

.main_footer_inner_div1 img {
    width: 36%;
    aspect-ratio: 1/1;
    object-fit: contain;
}

.main_footer_inner_div2 {
    width: 33%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    /* background-color: purple; */
}

.main_footer_inner_heading {
    width: 100%;
    font-size: 28px;
    font-weight: 800;
}

.main_footer_inner_quick_links {
    width: 90%;
}

.main_footer_inner_content_div {
    width: 100%;
}

.main_footer_inner_div3 {
    width: 33%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* background-color: aqua; */
}

.main_footer_inner_contant_us {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.main_footer_inner_contant_us div {
    margin: 0%;
    padding: 0%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.main_footer_inner_div3_icons_div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 20%;
}

.icon_div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.icon_div img {
    width: 15%;
    cursor: pointer;
}










@media screen and (max-width: 850px) {
    .main_footer_section {
        padding: 30px 30px;
    }

    .main_footer_div {
        width: 100%;
        flex-direction: column;
        gap: 20px;
    }

    .main_footer_inner_div1 {
        width: 100%;
        justify-content: space-evenly;
    }

    .main_footer_inner_div2 {
        width: 100%;
    }

    .main_footer_inner_div3 {
        width: 100%;
    }

    .main_footer_inner_div3_icons_div {
        padding-top: 5%;
    }

    .main_footer_inner_div1 img {
        width: 23%;
    }

    .icon_div img {
        width: 10%;
    }
}

@media screen and (max-width: 600px) {
    .main_footer_inner_content_div {}

    .main_footer_inner_div1 img {
        width: 30%;
    }

    .icon_div img {
        width: 15%;
    }
}