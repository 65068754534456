.main_faq_page_div {
    position: relative;
    top: 123px;
}


.main_bgCard {
    position: relative;
    top: 125px;
    background-image: url('../assets/images/map (2).jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bgCard {
    background-color: rgba(0, 0, 0, 0.664);
}

.about_page_heading {
    width: 100%;
    font-size: 50px;
    font-weight: bolder;
    color: #fff;
    padding-bottom: 20px;
}

.about_us_banner_div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding-bottom: 30px;
}

.main_BOARD_OF_ADVISORS_section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.inner_BOARD_OF_ADVISORS_section {
    width: 1200px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;
    padding: 5% 0;
}

.main_cards_div {
    width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    cursor: pointer;
    border-radius: 10px;
    overflow: hidden;
    transition: all 0.9s;
}

.main_cards_top_div {
    width: 100%;
    height: 130px;
    background-color: white;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: 0.9s;
}

.profile_images_holder_div {
    width: 180px;
    height: 180px;
    background-color: black;
    border-radius: 100px;
    position: relative;
    top: 75px;
    z-index: 1;
    overflow: hidden;
    border: 2px solid #2B6CB0;
    transition: 0.9s;
}

.profile_images_holder_div img {
    width: 100%;
    transition: 0.9s;
}







.main_cards_body_div {
    width: 100%;
    height: 630px;
    background-color: white;
    border-radius: 8px;
    /* padding-top: 70px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    transition: 0.9s;
}

.inner_cards_body_div1 {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 90px;
    transition: 0.9s;
}

.name_div {
    width: fit-content;
    height: fit-content;
    padding: 10px 20px;
    font-size: 25px;
    font-weight: 600;
    background-color: #2B6CB0;
    border: 2px solid #2B6CB0;
    color: white;
    /* position: relative; */
    /* z-index: 2; */
    /* top: ; */
    border-radius: 100px;
    transition: 0.9s;
}

.inner_cards_body_div2 {
    font-size: 15px;
    font-weight: 600;
    color: black;
    text-align: justify;
    transition: 0.9s;
    padding: 20px 15px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    transition: 0.9s;
}

.inner_cards_body_div2 div {
    width: 100%;
    height: fit-content;
    font-size: 20px;
    font-weight: 600;
    color: #2B6CB0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.inner_cards_body_div3 {
    border-top: 2px solid lightgray;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.inner_cards_body_div3 button {
    width: 150px;
    border-radius: 5px;
    padding: 5px 0;
    font-size: 18px;
    font-weight: 600;
    background-color: #2B6CB0;
    color: white;
}

.main_cards_div:hover {

    .main_cards_top_div {
        border-radius: 0px;
    }

    .main_cards_body_div {
        border-radius: 0px;
    }

    .name_div {
        border: 2px solid #03a07b;
        color: #03a07b;
        border-radius: 5px;
        background-color: transparent;
    }

    .profile_images_holder_div {
        border: 3px solid #03a07b;
    }

    .inner_cards_body_div1 {
        padding-top: 110px;
    }

    .inner_cards_body_div2 div {
        color: #03a07b;
    }

    gap: 0;
    transition: 0.9s;
}


.faq_payment_table_div {
    width: 100%;
    padding: 1rem 0;
}

.faq_payment_table_div table thead tr th {
    background-color: #2B6CB0;
    color: white;
    padding: 10px;
    border: 2px solid white !important;
}

.faq_payment_table_div tbody tr td {
    padding: 10px;
    border: 2px solid white !important;
}

.faq_payment_table_div tbody tr:nth-child(even) {
    background-color: #E7EAED;
}

.faq_payment_table_div tbody tr:nth-child(odd) {
    background-color: #CCD2D8;
}




@media screen and (max-width: 850px) {
    .about_page_heading {
        font-size: 30px;
    }

    .css-1v93odl {
        font-size: 13px !important;
    }

    .main_bgCard {
        top: 100px;
    }

    .inner_BOARD_OF_ADVISORS_section {
        width: 100%;
    }

    .main_faq_page_div {
        top: 100px;
    }
}