* {
    margin: 0%;
    padding: 0%;
}

body {
    margin: 0%;
    padding: 0%;
}

.sub_header_dashboard {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
    padding: 0 20px;
    position: relative;
}

.sub_header_dashboard button img {
    width: 30px;
}

.main_show_sub_header {
    width: 150px;
    height: fit-content;
    position: absolute;
    right: 51px;
    top: 33px;
    z-index: 999;
    display: none;
}

.inner_show_sub_header {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 10px 5px;
    background-color: white;
    box-shadow: 0px 0px 5px 0px lightgray;
    border-radius: 15px 0px 15px 15px;
}

.sub_header_list {
    width: 100%;
    border-bottom: 1px solid lightgray;
    padding: 5px 0;
    cursor: pointer;
    font-size: 15px;
    font-weight: 600;
}

.sub_header_list:hover {
    border-bottom: 1px solid gray;
}

.inner_show_sub_header a {
    width: 100%;
}