.main_home_page_section {
    position: relative;
    top: 125px;
}

.home_how_it_works_main_section {
    width: 100%;
    height: fit-content;
    background-image: url("https://storage.googleapis.com/asei-632d2.appspot.com/web_assets/1712681260851-how.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.home_how_it_works_inner_section {
    width: 100%;
    height: fit-content;
    padding: 3% 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #011f43be;
}

.home_how_it_works_inner_div {
    width: 1200px;
    /* height: 20px; */
    height: fit-content;
    /* background-color: black; */
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding: 0 2%;
}

.home_how_it_works_inner_div_heading {
    font-size: var(--chakra-fontSizes-5xl);
    font-weight: 600;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.home_how_it_works_inner_div_heading img {
    width: 60px;
    mix-blend-mode: color-burn;
}

.home_how_it_works_inner_div_sub_heading {
    width: 80%;
    padding: 0 2%;
}

.home_how_it_works_inner_div_sub_heading p {
    font-size: 15px;
    font-weight: 600;
    text-align: justify;
}

.home_how_it_works_inner_div_video {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.home_how_it_works_inner_div_video video {
    width: 50%;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0px 0px 10px 0px gray;
}

.main_testimonials_section {
    width: 100%;
    padding: 3% 0;
    background-color: #F0F0F7;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.main_testimonials_inner_div {
    width: 1200px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
}

.inner_testimonials_inner_div1 {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
}

.inner_testimonials_inner_div2 {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.inner_testimonials_slider_div {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    padding: 30px 0;
}


.inner_testimonials_slider_heading_div {
    font-size: 30px;
    font-weight: 800;
    color: #195389;
}





.main_testimonials_dropdown_div {
    width: 60%;
    height: fit-content;
}

.main_testimonials_dropdown_inner_div {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.main_testimonials_dropdown_inner_div div {
    width: 100%;
}


/* testimonials card css start here */

.testimonials_main_card_div {
    width: 100%;
    height: fit-content;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.testimonials_main_card_div video {
    width: 50%;
    border-radius: 10px;
}

.testimonials_main_card_inner_div1 {
    width: 100%;
    padding: 10px;
    background-color: white;
    border-radius: 5px;
    position: relative;
    z-index: 1;
    font-size: 16px;
    font-weight: 500;
}

.testimonials_main_card_inner_div2 {
    width: 100%;
}

.box {
    width: 30px;
    height: 30px;
    background-color: white;
    position: relative;
    top: -17px;
    left: 50px;
    z-index: 0;
    transform: rotate(135deg);
}

.testimonials_main_card_inner_div3 {
    font-size: 25px;
    font-weight: 600;
}

.slider_buttons_div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    padding-right: 2%;
    gap: 20px;
}

.slider_buttons_div button {
    color: #84A1C0;
    font-size: 30px;
}

/* testimonials card css end here */




/* Home Banner Css Start Here */

.main_home_banner_div {
    width: 100%;
    height: fit-content;
    padding: 8% 0;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../../assets/images/home_page_background.webp);
    /* background-image: url(../../assets/images/home_page_background.webp); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    filter: brightness(100%);
}

.inner_home_banner_div {
    width: 1200px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    color: white;
}

.heading_home_banner_div {
    width: 100%;
    height: fit-content;
    font-size: 50px;
    font-weight: bolder;
    text-align: center;
}

.sub_heading_home_banner_div {
    width: 100%;
    height: fit-content;
    font-size: 25px;
    font-weight: bolder;
    text-align: center;
    font-style: italic;
}

.contant_home_banner_div {
    width: 80%;
    height: fit-content;
    font-size: 17px;
    font-weight: 500;
    text-align: center;
}

.button_home_banner_div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.button_home_banner_div a {
    text-decoration: none;
}

/* .button_home_banner_div a button {
    width: fit-content;
    height: fit-content;
    padding: 5px 20px;
    text-align: center;
    background-color: #ff682d;
    color: white;
    font-size: 20px;
    font-weight: 600;
    border-radius: 100px;
    cursor: pointer;
} */

.button {
    width: fit-content;
    height: fit-content;
    border-radius: 100px;
    background-color: transparent;
    border: 2px solid #f4511e;
    color: #f4511e;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    padding: 7px 25px;
    transition: all 0.2s;
    cursor: pointer;
}

.button:hover {
    background-color: #f4511e;
    color: white;
}

.button span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.2s;
}

.button span:after {
    content: '\00bb';
    font-size: 30px;
    position: absolute;
    opacity: 0;
    top: -11px;
    right: -20px;
    transition: 0.5s;
}

.button:hover span {
    padding-right: 25px;
}

.button:hover span:after {
    opacity: 1;
    right: 0;
}

/* Home Banner Css End Here */


.home_inner_sections_div1 {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5% 0;
}

.inner_home_inner_sections_div1 {
    width: 1200px;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.left_home_inner_sections_div1 {
    width: 55%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.left_heading_home_inner_sections_div1 {
    font-size: 40px;
    font-weight: bolder;
}

.left_contant_home_inner_sections_div1 {
    text-align: justify;
    font-size: 17px;
    font-weight: 500;
}

.left_button_home_inner_sections_div1 {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}








.right_home_inner_sections_div1 {
    width: 45%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.right_home_inner_sections_div1 img {
    width: 100%;
    position: relative;
    transition: top 0.5s;
    animation: topToBottom 5s linear infinite, bottomToTop 5s linear infinite;
}

.certificates_img_div {
    width: 70%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.certificates_img_div img {
    width: 100%;
    position: relative;
    transition: top 0.5s;
}

@keyframes topToBottom {

    0%,
    100% {
        top: -15px;
    }

    50% {
        top: 15px;
    }
}

@keyframes bottomToTop {

    0%,
    100% {
        top: 15px;
    }

    50% {
        top: -15px;
    }
}




.home_inner_sections_div2 {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5% 0;
}

.inner_home_inner_sections_div2 {
    width: 1200px;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
}

.left_home_inner_sections_div2 {
    width: 55%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.left_heading_home_inner_sections_div2 {
    font-size: 40px;
    font-weight: bolder;
}

.left_contant_home_inner_sections_div2 {
    text-align: justify;
    font-size: 17px;
    font-weight: 500;
}

.left_button_home_inner_sections_div2 {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}


.right_home_inner_sections_div2 {
    width: 45%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.right_home_inner_sections_div2 img {
    width: 100%;
    /* position: relative; */
    /* transition: top 0.5s; */
    /* animation: topToBottom 5s linear infinite, bottomToTop 5s linear infinite; */
}

.main_certificates_div {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}





@media screen and (max-width: 850px) {
    .main_home_page_section {
        top: 100px;
    }

    .inner_home_banner_div {
        width: 100%;
    }

    .inner_home_inner_sections_div1 {
        width: 90%;
        flex-direction: column;
    }

    .left_home_inner_sections_div1 {
        width: 100%;
    }

    .right_home_inner_sections_div1 {
        width: 80%;
    }

    .inner_home_inner_sections_div2 {
        width: 90%;
        flex-direction: column;
    }

    .right_home_inner_sections_div2 {
        width: 80%;
    }

    .left_home_inner_sections_div2 {
        width: 100%;
    }




    .home_how_it_works_inner_section {
        padding: 40px 0;
    }

    .home_how_it_works_inner_div {
        width: 100%;
    }

    .home_how_it_works_inner_div_heading {
        font-size: var(--chakra-fontSizes-3xl);
    }

    .home_how_it_works_inner_div_sub_heading {
        width: 100%;
    }

    .home_how_it_works_inner_div_video video {
        width: 90%;
    }

    .main_testimonials_inner_div {
        width: 90%;
        flex-direction: column;
    }

    .inner_testimonials_slider_div {
        width: 100%;
    }

    .main_testimonials_dropdown_div {
        width: 100%;
    }

    .inner_testimonials_inner_div2 {
        flex-direction: column;
        gap: 10px;
    }

    .testimonials_main_card_div {
        padding: 10px;
    }

    .testimonials_main_card_div video {
        width: 90%;
        border-radius: 10px;
    }

    .testimonials_main_card_inner_div3 {
        font-size: 20px;
    }

    .main_testimonials_dropdown_inner_div {
        padding: 10px;
    }

    .main_testimonials_dropdown_inner_div {
        font-size: 15px;
    }
}

@media screen and (max-width: 500px) {
    .left_home_inner_sections_div1 {
        width: 90%;
    }

    .left_home_inner_sections_div2 {
        width: 90%;
    }

    .inner_home_banner_div {
        gap: 20px;
    }

    .button {
        font-size: 15px;
    }

    .contant_home_banner_div {
        font-size: 15px;
    }

    .left_heading_home_inner_sections_div1 {
        font-size: 30px;
    }

    .left_heading_home_inner_sections_div2 {
        font-size: 30px;
    }

    .left_contant_home_inner_sections_div1 {
        font-size: 15px;
    }

    .left_contant_home_inner_sections_div2 {
        font-size: 15px;
    }

    .heading_home_banner_div {
        font-size: 30px;
    }
    
    .sub_heading_home_banner_div {
        font-size: 23px;
    }

    
}