* {
    margin: 0%;
    padding: 0%;
}

body {
    margin: 0%;
    padding: 0%;
}

.main_user_guide_div {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
    background-image: url("https://res.cloudinary.com/dabkyosdo/image/upload/v1707043729/web_assets/smart-background-8dz6l1e92qeb7jac.jpg_83856903-9ceb-4f1a-baa0-393ad4e62222.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom right;
}

.inner_user_guide_div {
    width: 90%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.inner_user_guide_div div {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.inner_user_guide_div div h1 {
    font-size: 2.8rem;
    font-weight: bolder;
    color: #195389;
}

.inner_user_guide_div div p {
    font-size: 1.8rem;
    color: black;
    font-weight: 600;
    text-align: center;
}

.inner_user_guide_div div video {
    width: 60%;
    margin: 1rem 0;
    padding: 0.3rem;
    border-radius: 10px;
    border: 2px solid #03A9F4;
    box-shadow: 0px 0px 10px 0px #03A9F4;
}

.inner_user_guide_div div iframe {
    margin: 1rem 0;
    padding: 0.3rem;
    border-radius: 10px;
    border: 2px solid #03A9F4;
    box-shadow: 0px 0px 10px 0px #03A9F4;
}

.main_User_Guide_image_div {
    display: none !important;
    width: 85% !important;
    padding: 0.3rem;
    border-radius: 10px;
    border: 2px solid #03A9F4;
    box-shadow: 0px 0px 10px 0px #03A9F4;
}

.main_User_Guide_image_heading_div {
    display: none !important;
}

.main_User_Guide_image_div img {
    width: 98%;
}

.main_certificate_request_field_div {
    width: 85% !important;
    padding: 0.3rem;
    border-radius: 10px;
    border: 2px solid #03A9F4;
    box-shadow: 0px 0px 10px 0px #03A9F4;
}

.main_certificate_request_field_div ol {
    width: 95%;
}

.main_certificate_request_field_div ol li {
    font-size: 1.1rem;
    font-weight: 500;
}


@media screen and (max-width: 600px) {
    .inner_user_guide_div div h1 {
        font-size: 2.3rem;
        text-align: center;
    }

    .inner_user_guide_div div p {
        font-size: 1.3rem !important;
    }

    .main_User_Guide_image_div {
        display: block !important;
    }

    .main_User_Guide_image_heading_div {
        display: block !important;
    }

    .main_User_Guide_pdf_div {
        display: none !important;
    }

    .main_User_Guide_pdf_heading_div {
        display: none !important;
    }

    .main_User_Guide_video_div video {
        width: 100% !important;
    }

    .main_User_Guide_image_div {
        width: 100% !important;
    }

    .main_certificate_request_field_div {
        width: 100% !important;
    }

    .main_certificate_request_field_div ol {
        width: 85%;
    }
}