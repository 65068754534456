* {
    margin: 0%;
    padding: 0%;
}

body {
    margin: 0%;
    padding: 0%;
}

.main_blog_page_div {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 125px;
}

.main_blog_hero_section_div {
    width: 100%;
    height: 400px;
    background-image: url("https://storage.googleapis.com/asei-632d2.appspot.com/web_assets/1712680871976-blog_banner_img.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.inner_blog_hero_section_div {
    width: 1200px;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.left_blog_hero_section_div {
    width: 40%;
    height: 100%;
    padding: 20px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    font-weight: bolder;
}

.right_blog_hero_section_div {
    width: 60%;
    height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.right_inner_div1 {
    width: 20%;
}

.right_inner_div2 {
    width: 65%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.right_inner_div3 {
    width: 20%;
}

.larg_circle_holder {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
}

.sm_circle_div {
    width: 150px;
    height: 150px;
    border-radius: 100px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
}

.lg_circle_div {
    width: 200px;
    height: 200px;
    border-radius: 100px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
}

#circle_holder1 {
    justify-content: start;
    gap: 40px;
}

.circle_text_div {
    width: 150px;
    height: 50px;
    font-size: 11px;
    padding: 2px 3px;
    line-height: 1.2;
    border-radius: 5px;
    background-color: white;
    border-left: 5px solid #0A3161;
    box-shadow: 0px 0px 5px 0px gray;
    position: relative;
    top: 15px;
    right: -60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#person1 {
    background-image: url("https://res.cloudinary.com/dabkyosdo/image/upload/v1707037440/web_assets/wassim.png_605955f0-d551-4bf5-9bd2-7cc09902c0aa.png");
    background-position: top left;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0px 0px 3px 0px gray;
}

#person2 {
    background-image: url("https://res.cloudinary.com/dabkyosdo/image/upload/v1707037398/web_assets/martha.png_760a08a4-4fda-4e07-a985-8a22070d14e5.png");
    background-position: top left;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0px 0px 3px 0px gray;
}

#person3 {
    background-image: url("https://res.cloudinary.com/dabkyosdo/image/upload/v1707037346/web_assets/salman_abedin.png_35bfb92d-ce73-4bff-b0e2-e76d04d73119.png");
    background-position: top left;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0px 0px 3px 0px gray;
}

#person4 {
    background-image: url("https://res.cloudinary.com/dabkyosdo/image/upload/v1707037217/web_assets/imran_amin.png_8b08ec78-cdaa-4263-8973-2da3219019ba.png");
    background-position: top left;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0px 0px 3px 0px gray;
}

#person5 {
    background-image: url("https://res.cloudinary.com/dabkyosdo/image/upload/v1707037294/web_assets/javaid_laghari.png_88cb67b2-e7d0-4c12-966d-ea19f1611b05.png");
    background-position: top left;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0px 0px 3px 0px gray;
}

.main_all_blogs_show_div {
    width: 100%;
    height: fit-content;
    padding: 5% 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.inner_all_blogs_show_div {
    width: 1200px;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;
}

.main_blog_card_div {
    width: 550px;
    height: 500px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 0px 5px 0px gray;
}

.main_blog_card_div:hover{
    cursor: pointer;
}   

.blog_image_div {
    width: 100%;
    height: 42%;
}

.blog_image_div img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.blog_body_div {
    width: 100%;
    height: 58%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
}

.blog_heading_div {
    width: 100%;
    height: fit-content;
    font-size: 20px;
    font-weight: 600;
}

.blog_description_div {
    width: 100%;
    height: fit-content;
    font-size: 16px;
    text-align: justify;
}

.blog_date_div {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
}

.blog_date_div p {
    font-size: 13px;
    font-weight: bolder;
}

.blog_read_more_button_div {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
}

.blog_read_more_button_div button {
    padding: 5px 20px;
    background-color: transparent;
    border: 2px solid #f4511e;
    color: #f4511e;
    border-radius: 100px;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
}

.blog_read_more_button_div button:hover {
    background-color: #f4511e;
    color: white;
}

@media screen and (max-width: 850px) {
    .inner_all_blogs_show_div {
        width: 100%;
    }

    .larg_circle_holder {
        justify-content: start;
    }

    .right_inner_div2 {
        width: fit-content;
    }

    .inner_blog_hero_section_div {
        width: 100%;
        height: fit-content;
        flex-direction: column;
    }

    .sm_circle_div {
        width: 100px;
        height: 100px;
    }

    .lg_circle_div {
        width: 150px;
        height: 150px;
    }
}

@media screen and (max-width: 650px) {
    .main_blog_hero_section_div {
        height: 300px;
    }

    .main_blog_card_div {
        width: 95%;
        height: fit-content;
    }
}