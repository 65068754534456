* {
    margin: 0%;
    padding: 0%;
}

body {
    margin: 0%;
    padding: 0%;
}

.main_download_icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.main_download_icon button {
    font-size: 15px;
    font-weight: 600;
    padding: 5px 15px;
    margin: 1.5% 0;
    border: 2px solid #1F487C;
    color: #1f487c;
    border-radius: 100px;
    cursor: pointer;
}

.main_download_icon i svg {
    font-size: 42.5px;
    padding: 20% 0;
}

.download_icon {
    width: 37px;
    cursor: pointer;
}



/* Example CSS stylesheet */
.tag {
    background-color: #90EE90 !important;
    /* Example color with !important */
    /* Add styles for other colors as needed */
}

.main_start_reading_text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.start_reading_text {
    background-color: lightgray;
    padding: 1px 8px;
    border-radius: 100px;
    color: black !important;
}

.no_data_found_div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.main_history_show_top_div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    flex-wrap: wrap;
}

.inner_history_show_top_div1 {}

.inner_history_show_top_div2 {
    display: flex;
    flex-direction: column;
    gap: 15px;
    color: gray;
}

.history_level {
    font-size: 20px;
    font-weight: bold;
    text-decoration: underline;
    text-underline-offset: 5px;
}







.main_resume_div {
    width: 100%;
    border: 1px solid rgb(207, 207, 207);
    border-radius: 5px;
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
}

.main_resume_div img {
    width: 2rem;
}

.main_resume_div button {
    width: fit-content;
    background-color: rgb(207, 207, 207);
    color: black;
    border: 1px solid black;
    border-radius: 5px;
    padding: 0 0.5rem;
}

.upload_cv_heading {
    width: fit-content;
    font-size: 1rem;
    font-weight: 600;
    border: 1px solid gray;
    border-radius: 2px;
    padding: 0.2rem 1rem;
    margin-bottom: 1rem;
    animation: colorChange 3s infinite;
}

@keyframes colorChange {
    0% {
        background-color: red;
        color: white;
        border: 1px solid red;
    }

    100% {
        background-color: transparent;
        color: black;
        border: 1px solid gray;
    }
}