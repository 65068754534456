* {
    margin: 0%;
    padding: 0%;
}

body {
    margin: 0%;
    padding: 0%;
}

.contact_us_main_div {
    width: 100%;
    height: fit-content;
    background-image: url("https://storage.googleapis.com/asei-632d2.appspot.com/web_assets/1712681174067-form_background_img.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    top: 125px;
}

.main_contact_us_div {
    width: 100%;
    height: fit-content;
    background-color: rgba(0, 0, 0, 0.301);
    padding: 5% 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact_form_main_div {
    width: 700px;
    height: fit-content;
    background-image: url("https://storage.googleapis.com/asei-632d2.appspot.com/web_assets/1712681292544-hurricane-aperature-3d-background.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 5px;
    border: 8px solid #0F95D6;
}

.main_contact_form_div {
    width: 100%;
    height: fit-content;
    background-color: rgba(0, 0, 0, 0.616);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
}

.inner_main_contact_form_div_hold_heading_and_form {
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.contant_form_header_div {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: white;
}

.inner_contant_form_header_div1 {
    width: 80%;
    height: fit-content;
    text-align: center;
    font-size: 40px;
    font-weight: bolder;
}

.inner_contant_form_header_div2 {
    width: 80%;
    height: fit-content;
    text-align: center;
    font-size: 16px;
}

.contant_form_body_div {
    width: 100%;
    height: fit-content;
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.input_holrer {
    width: 100%;
    height: fit-content;
}

.custom-field {
    width: 100%;
    position: relative;
    font-size: 16px;
    margin-bottom: 5px;
    display: inline-block;
    --field-padding: 12px;
}

.custom-field input {
    border: none;
    color: white;
    -webkit-appearance: none;
    -ms-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    padding: var(--field-padding);
    border-radius: 3px;
    width: 100%;
    outline: none;
    font-size: 16px;
}

.custom-field .placeholder {
    position: absolute;
    left: var(--field-padding);
    width: calc(100% - (var(--field-padding) * 2));
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    top: 22px;
    line-height: 100%;
    transform: translateY(-50%);
    color: #167ccf;
    transition:
        top 0.6s ease,
        color 0.6s ease,
        font-size 0.6s ease;
}

.custom-field input.dirty+.placeholder,
.custom-field input:focus+.placeholder,
.custom-field input:not(:placeholder-shown)+.placeholder {
    top: -10px;
    font-size: 12px;
    color: white;
}



/* TWO */
.custom-field.two input {
    border-radius: 0;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background:
        linear-gradient(90deg, #167ccf, #167ccf) center bottom/0 0.15em no-repeat,
        linear-gradient(90deg, #8fbee5, #8fbee5) left bottom/100% 0.15em no-repeat;
    /* linear-gradient(90deg, #fafafa, #fafafa) left bottom/100% no-repeat; */
    transition: background-size 0.6s ease;
}

.custom-field.two input.dirty,
.custom-field.two input:not(:placeholder-shown),
.custom-field.two input:focus {
    background-size: 100% 0.15em, 100% 0.1em, 100%;
}

.contant_form_footer_div {
    width: 100%;
    height: 10%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
}

.contant_form_footer_div button {
    width: fit-content;
    height: fit-content;
    font-size: 17px;
    font-weight: 600;
    text-align: center;
    padding: 5px 30px;
    background-color: transparent;
    border: 2px solid #f4511e;
    border-radius: 5px;
    cursor: pointer;
    color: #f4511e;
    transition: 0.9s;
}

.contant_form_footer_div button:hover {
    background-color: #f4511e;
    color: white;
}

@media screen and (max-width: 850px) {
    .contact_us_main_div {
        top: 100px;
    }

    .contact_form_main_div {
        width: 550px;
    }
}

@media screen and (max-width: 600px) {
    .main_contact_us_div {
        top: 100px;
    }

    .contact_form_main_div {
        width: 300px;
    }

    .inner_contant_form_header_div1 {
        width: 100%;
        font-size: 30px;
    }
}