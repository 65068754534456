* {
    margin: 0%;
    padding: 0%;
}

body {
    margin: 0%;
    padding: 0%;
}

.error_only_doc_docx_pdf_heading {
    width: fit-content;
    font-size: 17px;
    font-weight: 600;
    color: black;
    padding: 10px;
    border-radius: 5px;
    background-color: rgb(221, 152, 49);
}

.css-a5mhaz {
    padding: var(--chakra-space-4);
    outline: 2px solid transparent;
    outline-offset: 2px px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 10px;
}

.css-1mc5l2u {
    width: 100%;
}

.main_skills_div {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.main_show_skills_div {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 10px;
}

.show_skills_div {
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.main_add_badge_button {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
}

/* Loader css Start here */
.main_loader_div {
    position: fixed;
    z-index: 999;
    top: 0%;
    background-color: rgba(128, 128, 128, 0.712);
    width: 100vw;
    height: 100vh;
    /* display: none; */
}

.main_loader_inner_div {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loader {
    width: 64px;
    height: 64px;
    position: relative;
    background-image:
        linear-gradient(#FFF 16px, transparent 0),
        linear-gradient(#0A3161 16px, transparent 0),
        linear-gradient(#0A3161 16px, transparent 0),
        linear-gradient(#FFF 16px, transparent 0);
    background-repeat: no-repeat;
    background-size: 16px 16px;
    background-position: left top, left bottom, right top, right bottom;
    animation: rotate 1s linear infinite;
}

@keyframes rotate {
    0% {
        width: 64px;
        height: 64px;
        transform: rotate(0deg)
    }

    50% {
        width: 30px;
        height: 30px;
        transform: rotate(180deg)
    }

    100% {
        width: 64px;
        height: 64px;
        transform: rotate(360deg)
    }
}

/* Loader css End here */



.modal {
    width: 100%;
    display: none;
    position: fixed;
    z-index: 999;
    top: 0%;
}

.modal-content {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.notification_box {
    margin-top: 1%;
    width: 500px;
    /* height: 70px; */
    padding: 30px 0px;
    color: white;
    border-radius: 10px;
    background-color: rgb(57, 184, 63);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1%;
}

.modal.show {
    display: block;
    /* Additional styling for the visible modal */
    /* ... */
}