* {
    padding: 0px;
    margin: 0px;
}

body {
    padding: 0px;
    margin: 0px;
}

.main_account_activate_div {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0%;
    left: 0%;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 99;
}

#account_activate_container {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: calc(50px);
}

.account_activate_message {
    font-size: 40px;
    color: #0A3161;
}

.account_activate_message:after {
    content: "]";
}

.account_activate_message:before {
    content: "[";
}

.account_activate_message:after,
.account_activate_message:before {

    color: #0A3161;
    font-size: 50px;
    -webkit-animation-name: opacity;
    -webkit-animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: opacity;
    animation-name: opacity;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    margin: 0 50px;
}

@-webkit-keyframes opacity {

    0%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

@keyframes opacity {

    0%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

@keyframes out {
    0% {
        r: 1;
        opacity: 0.9;
    }

    25% {
        r: 5;
        opacity: 0.3;
    }

    50% {
        r: 10;
        opacity: 0.2;
    }

    75% {
        r: 15;
        opacity: 0.1;
    }

    100% {
        r: 20;
        opacity: 0;
    }
}

.btn {
    width: 15vw;
    padding: 10px 20px;
    background-color: #0A3161;
    color: white;
    font-size: 15px;
    border-radius: 10px;
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .account_activate_message {
        font-size: 20px;
    }

    .account_activate_message:after,
    .account_activate_message:before {
        font-size: 30px;
        margin: 0 20px;
    }

    .btn {
        width: 35vw;
    }
}



.main_laoder_div {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0%;
    left: 0%;
    background-color: rgba(0, 0, 0, 0.712);
    z-index: 999;
    display: none;
}

.inner_laoder_div {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loader {
    width: 64px;
    height: 64px;
    position: relative;
    background-image:
        linear-gradient(#FFF 16px, transparent 0),
        linear-gradient(#0A3161 16px, transparent 0),
        linear-gradient(#0A3161 16px, transparent 0),
        linear-gradient(#FFF 16px, transparent 0);
    background-repeat: no-repeat;
    background-size: 16px 16px;
    background-position: left top, left bottom, right top, right bottom;
    animation: rotate 1s linear infinite;
}

@keyframes rotate {
    0% {
        width: 64px;
        height: 64px;
        transform: rotate(0deg)
    }

    50% {
        width: 30px;
        height: 30px;
        transform: rotate(180deg)
    }

    100% {
        width: 64px;
        height: 64px;
        transform: rotate(360deg)
    }
}








#error_account_activate_container {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: calc(50px);
}

.error_account_activate_message {
    font-size: 40px;
    color: #980f0f;
}

.error_account_activate_message:after {
    content: "]";
}

.error_account_activate_message:before {
    content: "[";
}

.error_account_activate_message:after,
.error_account_activate_message:before {

    color: #980f0f;
    font-size: 50px;
    -webkit-animation-name: opacity;
    -webkit-animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: opacity;
    animation-name: error_opacity;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    margin: 0 50px;
}

@-webkit-keyframes error_opacity {

    0%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

@keyframes error_opacity {

    0%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

@keyframes error_out {
    0% {
        r: 1;
        opacity: 0.9;
    }

    25% {
        r: 5;
        opacity: 0.3;
    }

    50% {
        r: 10;
        opacity: 0.2;
    }

    75% {
        r: 15;
        opacity: 0.1;
    }

    100% {
        r: 20;
        opacity: 0;
    }
}