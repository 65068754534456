* {
    padding: 0px;
    margin: 0px;
}

body {
    margin: 0%;
    padding: 0%;
}

.main_404_div {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0%;
    left: 0%;
    background-color: white;
    background-image: url(../../assets/images/home_page_background.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.st0 {
    font-family: 'FootlightMTLight';
}

.st1 {
    font-size: 83.0285px;
}

.st2 {
    fill: gray;
}

.svg {
    width: 500px;
    height: 300px;
    text-align: center;
    fill: #195389;
}

path#XMLID_5_ {

    fill: #195389;
    filter: url(#blurFilter4);
}

path#XMLID_11_,
path#XMLID_2_ {
    fill: #195389;
}

.circle {
    animation: out 2s infinite ease-out;
    fill: #C81E25;
}

#container {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.658);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.message {
    color: #195389;
    font-size: 30px;
}

.message:after {
    content: "]";
}

.message:before {
    content: "[";
}

.message:after,
.message:before {
    color: #C81E25;
    font-size: 35px;
    -webkit-animation-name: opacity;
    -webkit-animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: opacity;
    animation-name: opacity;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    margin: 0 50px;
}

.go_back_button {
    padding: 0.8% 3%;
    background-color: #195389;
    border-radius: 10px;
    color: white;
    font-size: 20px;
    cursor: pointer;
    margin-top: 2%;
}

@-webkit-keyframes opacity {

    0%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

@keyframes opacity {

    0%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

@keyframes out {
    0% {
        r: 1;
        opacity: 0.9;
    }

    25% {
        r: 5;
        opacity: 0.3;
    }

    50% {
        r: 10;
        opacity: 0.2;
    }

    75% {
        r: 15;
        opacity: 0.1;
    }

    100% {
        r: 20;
        opacity: 0;
    }
}


@media screen and (max-width: 600px) {
    .message {
        font-size: 20px;
    }

    .svg {
        width: auto;
        height: auto;
    }

    .message:after,
    .message:before {
        font-size: 20px;
    }

    .go_back_button {
        padding: 5px 20px;
        font-size: 18px;
    }
}